import '../scss/main.scss';
import Carousel from './carousel';

const menuToggleBtn = document.querySelector('.header__menutoggle');
const navEl = document.querySelector('.header__navCol');

menuToggleBtn.addEventListener('click', (e) => {
  e.preventDefault();
  navEl.classList.toggle('visible-mobile');
  menuToggleBtn.classList.toggle('active');
});

const testimonials = document.querySelectorAll('.hero__testimonials');
for (let i = 0; i < testimonials.length; i++) {
  let c = new Carousel(testimonials[i], {
    slidesPerView: 1,
    loop: false,
    spaceBetween: 30,
    grabCursor: true,
    watchOverflow: true,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 45,
      },
      1000: {
        slidesPerView: 3,
        spaceBetween: 60,
      },
    },
  });
}

const stimmen = document.querySelectorAll('.home-stimmen');
for (let i = 0; i < stimmen.length; i++) {
  let c = new Carousel(stimmen[i], {
    loop: false,
    spaceBetween: 30,
    grabCursor: true,
    watchOverflow: true,
  });
}

const catToggle = document.querySelector('.blogindex__cats__toggle');
if (catToggle) {
  catToggle.addEventListener('click', (e) => {
    e.preventDefault();
    document.querySelector('.blogindex__cats').classList.toggle('visible');
  });
}
