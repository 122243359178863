import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);
import 'swiper/swiper-bundle.css';

export default class Carousel {
  constructor(el, config) {
    const carouselEl = el.querySelector('.swiper-container');
    let newConfig = {
      navigation: {
        nextEl: el.querySelector('.swiper-button-next'),
        prevEl: el.querySelector('.swiper-button-prev')
      }
    };
    this.swiper = new Swiper(carouselEl, Object.assign(config, newConfig));
  }
}